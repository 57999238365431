import React, { useMemo, useState, useEffect, useCallback } from "react";
import { get, post, put, remove } from "../shared/http/httpService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { DeleteRowModal } from "../shared/helper/tableHelper";
import EditModal from "./Components/IntentsEditModal.component";
import NewIntent from "./Components/NewIntent.component";
import { orderAlphabeticallyWithAttribute } from "../shared/helper/orderAlphabetically";
import { Chip } from "@mui/material";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import HelpIcon from "@mui/icons-material/Help";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Tooltip } from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";

import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { defaultAgent } from "../shared/helper/agentsHelper";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { mkConfig, generateCsv, download } from "export-to-csv";
import Properties from "./Components/IntentsProperties.component";

import TracesHistory from "./Components/TracesHistory.component";

import { CopyIconIntents } from "../assets/svgs/intents-svg";

import "./intents.css";

const Intents = () => {
  const { t, i18n } = useTranslation("intents");
  const { enqueueSnackbar } = useSnackbar();
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const theme = useSelector((state) => state.theme);
  const [accountNames, setAccountNames] = useState([]);
  const [accountSelected, setAccountSelected] = useState("");
  const [tags, setTags] = useState([]);
  const [editIntent, setEditIntent] = useState(null);
  const [traces_intents_toggle, setTracesIntentsToggle] = useState(false);
  const [traces, setTraces] = useState([]);
  const typeOptions = [t("tooltipflows"), t("tooltipSQA"), t("tooltipDirect")];
  const [agentPages, setAgentPages] = useState([]);
  const [noTagsPermissions, setnoTagsPermissions] = useState(false);
  let [userPermissions, setUserPermissions] = React.useState(false);
  let auth = useSelector((state) => state.auth);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "intents" && permission.fullAccess !== true,
    );
    setUserPermissions(hasPermissions);
  };

  const columns = useMemo(
    () => [
      {
        header: t("intents:type"),
        accessorKey: "type",
        size: 20,
        filterVariant: "select",
        filterSelectOptions: typeOptions.sort(),
        filterFn: (rows, id, filterValue) => {
          if (filterValue.length === 0) return true;
          if (filterValue.includes(t("intents:tooltipDirect"))) {
            return rows.original.destinationPage ? true : false;
          }
          if (filterValue.includes(t("intents:tooltipSQA"))) {
            return rows.original.response &&
              rows.original.response.text &&
              rows.original.response.text.length &&
              !rows.original.destinationPage
              ? true
              : false;
          }
          if (filterValue.includes(t("intents:tooltipflows"))) {
            return !rows.original.response.text?.length > 0 &&
              !rows.original.destinationPage
              ? true
              : false;
          }
        },
        Cell: ({ row }) => {
          if (row.original.destinationPage) {
            return (
              <Tooltip
                id="direct-tooltip"
                title={t("intents:tooltipDirect")}
                placement="left">
                <ShortcutIcon id="direct-icon"></ShortcutIcon>
              </Tooltip>
            );
          }
          if (
            row.original.response && row.original.response.text
              ? row.original.response.text.length
              : row.original.response.length
          ) {
            return (
              <Tooltip
                id="sqa-tooltip"
                title={t("intents:tooltipSQA")}
                placement="left">
                <HelpIcon id="sqa-icon"></HelpIcon>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip
                id="flow-tooltip"
                title={t("intents:tooltipflows")}
                placement="left">
                <AccountTreeIcon id="flow-icon"></AccountTreeIcon>
              </Tooltip>
            );
          }
        },
      },
      {
        header: t("intents:name"),
        accessorKey: "name",
        size: 80,
        filterFn: (rows, id, filterValue) => {
          if (filterValue.length === 0) return true;
          let modifiedFilterValue = filterValue.replace(/\s/g, "-");
          const filterRegex = new RegExp(modifiedFilterValue, "i");
          let match = rows.original.name.toLowerCase().match(filterRegex);
          if (match) {
            return true;
          }
          return false;
        },
        Cell: ({ cell }) => {
          let name = cell.getValue();
          if (name.includes("-")) {
            name = name.replace(/-/g, " ");
          }
          name = name.charAt(0).toUpperCase() + name.slice(1);
          return name;
        },
      },
      {
        header: t("intents:phrases"),
        accessorKey: "phrases",
        size: 300,
        filterFn: (rows, id, filterValue) => {
          if (filterValue.length === 0) return true;
          if (rows.original.phrases) {
            const filterRegex = new RegExp(filterValue, "i");
            return rows.original.phrases.some((phrase) =>
              phrase.toLowerCase().match(filterRegex),
            );
          }
        },
        Cell: ({ cell }) => {
          let arrayPhrases = cell.getValue();
          return (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap", // Permitir que los elementos se envuelvan
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <div style={{ maxWidth: "calc(100% - 40px)" }}>
                {arrayPhrases?.map((x, index) => (
                  <Chip
                    key={index}
                    label={x}
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                  />
                ))}
              </div>
              <Tooltip title={t("intents:copyClipboard")} placement="top">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => copyToClipboard(arrayPhrases)}>
                  <CopyIconIntents
                    alt=""
                    style={{ width: "34px", height: "34px" }}
                  />
                </div>
              </Tooltip>
            </div>
          );
        },
      },
      {
        header: t("intents:answer"),
        accessorKey: "response.text",
        size: 400,
        filterFn: (rows, id, filterValue) => {
          if (filterValue.length === 0) return true;
          if (rows.original.response && rows.original.response.text) {
            const filterRegex = new RegExp(filterValue, "i");
            return rows.original.response.text.some((phrase) =>
              phrase.toLowerCase().match(filterRegex),
            );
          }
        },
        Cell: ({ cell }) => {
          let arrayResponse = cell.getValue();
          return (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap", // Permitir que los elementos se envuelvan
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <div style={{ maxWidth: "calc(100% - 40px)" }}>
                {arrayResponse?.map((x, index) => (
                  <Chip
                    key={index}
                    label={x.split("\n").map((text, index) => (
                      <div key={`${text}-${index}`}>
                        {text}
                        <br />
                      </div>
                    ))}
                    style={{ marginRight: "5px", marginBottom: "5px" }}
                  />
                ))}
              </div>
              {arrayResponse && arrayResponse.length > 0 && (
                <Tooltip title={t("intents:copyClipboard")} placement="top">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToClipboard(arrayResponse)}>
                    <CopyIconIntents
                      alt=""
                      style={{ width: "34px", height: "34px" }}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        header: t("intents:lastModified"),
        accessorKey: "updatedAt",
        size: 120,
        filterVariant: "date-range",
        filterFn: (rows, id, filterValue) => {
          if (!rows.original[id]) {
            return true;
          }
          // Convert the value of the row to a Date object and set the time to 00:00:00 to avoid problems with timezones and hours
          let rowValue = new Date(rows.original[id]);
          rowValue.setHours(0, 0, 0, 0);
          const rowValueDate = rowValue.toISOString().substring(0, 10);
          if (isNaN(rowValue)) {
            return false;
          }
          let minDate = filterValue[0] ? new Date(filterValue[0]) : null;
          let maxDate = filterValue[1] ? new Date(filterValue[1]) : null;
          // If minDate or maxDate is defined, extract the date part and compare it with the row value
          // and get only the date yyyy-mm-dd
          if (minDate) {
            minDate = minDate.toISOString().substring(0, 10);
            if (rowValueDate < minDate) {
              return false;
            }
          }
          if (maxDate) {
            maxDate = maxDate.toISOString().substring(0, 10);
            if (rowValueDate > maxDate) {
              return false;
            }
          }
          // If neither minDate nor maxDate is defined, or the row value is within the range, return true
          return (
            (!minDate && !maxDate) ||
            rowValueDate >= minDate ||
            rowValueDate <= maxDate
          );
        },
        Cell: ({ cell }) => {
          const dateValue = cell.getValue();
          if (!dateValue) {
            return "";
          }
          let date = new Date(dateValue);
          if (isNaN(date)) {
            return "";
          }
          date = date.toLocaleString("es-AR", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          });

          return date;
        },
        accessorFn: (row) => {
          const date = new Date(row.updatedAt);
          return isNaN(date) ? "" : date;
        },
        muiFilterCheckboxProps: {
          type: "date",
        },
      },
    ],
    [],
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    getUserPermisions();
    getAccountNames();
  }, []);

  const getAccountNames = useCallback(() => {
    get("/agents")
      .then(async (data) => {
        orderAlphabeticallyWithAttribute(data.agents, "display_name");
        const default_agent = defaultAgent(data.agents);
        setAccountNames(data.agents);
        setAccountSelected(default_agent.name);
      })
      .catch(() => {
        enqueueSnackbar(t("intents:errorGettingAccountNames"), {
          variant: "error",
        });
      });
  }, [enqueueSnackbar, setAccountNames, setAccountSelected, t]);

  const getIntents = useCallback(() => {
    try {
      get("/intents?agent=" + accountSelected, { applyTraces: true })
        .then((data) => {
          let intents = data.intents;
          let traces = data.intentsTracesRaw;
          intents.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
          );
          setData(intents);
          setTraces(traces);
        })
        .catch(() => {
          enqueueSnackbar(t("errorGettingRoute"), {
            variant: "error",
          });
        });
    } catch (error) {
      enqueueSnackbar(t("errorGettingRoute"), {
        variant: "error",
      });
    }
  }, [accountSelected, setData, enqueueSnackbar, t]);

  const getTags = useCallback(() => {
    get("/tags", null, true)
      .then((response) => {
        setTags(response.tags);
        setTracesIntentsToggle(response.traces_intents_toggle);
      })
      .catch(() => {
        setnoTagsPermissions(true);
      });
  }, [setTags]);

  const saveIntent = useCallback(
    (intent) => {
      intent.agent = accountSelected;
      post("/intents", intent)
        .then(() => {
          enqueueSnackbar(t("intents:intentCreated"), {
            variant: "success",
          });
          getIntents();
        })
        .catch(() => {
          enqueueSnackbar(t("intents:errorCreatingIntent"), {
            variant: "error",
          });
        });

      setNewModalOpen(false);
    },
    [accountSelected, enqueueSnackbar, getIntents, t, traces_intents_toggle],
  );

  const updateIntent = useCallback(
    (intent) => {
      put("/intents", intent)
        .then(() => {
          enqueueSnackbar(t("intents:intentUpdated"), {
            variant: "success",
          });
          getIntents();
        })
        .catch((error) => {
          let data = error.response.data;
          if (data.reservedWord) {
            enqueueSnackbar(
              t(`intents:${data.message}`) + ` ${data.reservedWord}`,
              {
                variant: "error",
              },
            );
          } else {
            enqueueSnackbar(t(`intents:${data.message}`), {
              variant: "error",
            });
          }
        });
      setEditModalOpen(false);
    },
    [enqueueSnackbar, getIntents, t, traces_intents_toggle],
  );

  const deleteIntent = useCallback(
    (intent) => {
      remove("/intents", { _id: intent._id, agent: accountSelected })
        .then(() => {
          enqueueSnackbar(t("intents:intentDeleted"), {
            variant: "success",
          });
          getIntents();
        })
        .catch((response) => {
          if (response.response.status === 424) {
            enqueueSnackbar(t("intents:intentInFlowgraph"), {
              variant: "error",
            });
          } else {
            enqueueSnackbar(t("intents:errorDeletingIntent"), {
              variant: "error",
            });
          }
        });
      setDeleteModalOpen(false);
    },
    [accountSelected, enqueueSnackbar, getIntents, t],
  );

  const validateNoDuplicateName = useCallback(
    (name, id) => {
      let formattedName = name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/\s/g, "-")
        .replace(/-+$/, "");
      let intent = data.find(
        (x) =>
          x.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .replace(/\s/g, "-") === formattedName,
      );
      if (intent && intent._id !== id) {
        return true;
      }
      return false;
    },
    [data],
  );

  const validateNoDuplicatePhrases = useCallback(
    (phrases, id) => {
      let response = false;
      phrases.forEach((phrase) => {
        let intent = data.find((x) => x.phrases.includes(phrase));
        if (intent && intent._id !== id) {
          response = { phrase: phrase, intent: intent.name };
        }
      });
      return response;
    },
    [data],
  );

  const handlePublish = async () => {
    await post("/traces/publish", { agent: accountSelected })
      .then((e) => {
        enqueueSnackbar(t("intents:newChangesPublish"), {
          variant: "success",
        });
        if (e.errors) {
          for (const err of e.errors) {
            enqueueSnackbar(err.message, {
              variant: "error",
            });
          }
        }
        getIntents();
      })
      .catch((errorMessage) => {
        enqueueSnackbar(
          `${errorMessage.name} :` + t("intents:errorDeleteInFlowgraph"),
          {
            variant: "error",
          },
        );
      });
  };

  const handleDeleteTraces = async () => {
    await remove("/traces/publish", {
      agent: accountSelected,
      collectionTraces: "intent",
    })
      .then((e) => {
        if (e.errors) {
          for (const err of e.errors) {
            enqueueSnackbar(err.message, {
              variant: "error",
            });
          }
        }
        getIntents();
      })
      .catch((errorMessage) => {
        enqueueSnackbar(
          `${errorMessage.name} :` + t("intents:errorDeleteTraces"),
          {
            variant: "error",
          },
        );
      });
  };

  useEffect(() => {
    if (accountSelected !== "") {
      getIntents();
      getTags();
      getAgentPages();
    }
  }, [accountSelected, getIntents, getTags]);

  const deleteValidate = (intentName) => {
    const name = intentName?.toLowerCase();
    if (
      name?.includes("default") &&
      name?.includes("welcome") &&
      name?.includes("intent")
    ) {
      return true;
    }
    return false;
  };

  const getAgentPages = async () => {
    await get("/flows?agent=" + accountSelected)
      .then((data) => {
        data = data?.nodes;
        data.sort((a, b) =>
          a.data.label.toUpperCase() > b.data.label.toUpperCase() ? 1 : -1,
        );
        setAgentPages(data);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingRoute"), {
          variant: "error",
        });
      });
  };

  const localeMap = {
    ES: "es",
    EN: "en",
  };

  const MemoizedNewIntent = React.memo(NewIntent);

  const prepareDataForExport = (tableData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: accountSelected + "_intents.csv",
    });

    const csvData = tableData.map((row) => {
      let destinationPageNameCSV = row.destinationPage;
      if (row.destinationPage) {
        destinationPageNameCSV = agentPages.find(
          (x) => x.id === row.destinationPage,
        )?.data?.label;
      }
      return {
        Name: row.name,
        Question: row.phrases ? row.phrases.join(",") : "",
        Answer: row.response.text ? row.response.text.join(",") : "",
        "Destination Page": destinationPageNameCSV,
        Agent: row.agent,
        "One Word": row.oneWord,
        "Exclude from Suggestions": row.excludeAlternatives,
        "Exclude from Intents Ranking": row.excludeRankings,
        "Suggestion Message": row.suggestion_message,
        Tags:
          row.tags && tags && tags.length > 0
            ? row.tags
              .map((tag) => tags.find((t) => t._id === tag)?.name)
              .join(",")
            : "",
      };
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  };

  const copyToClipboard = (chips) => {
    const text = chips.join("\n");
    navigator.clipboard.writeText(text);
    enqueueSnackbar(t("intents:questionsCopied"), {
      variant: "success",
    });
  };

  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={localeMap[i18n.language]}>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <Typography variant="h4" sx={{ mb: "1rem" }}>
            {t("intents:intents")}
          </Typography>
          <MaterialReactTable
            data={data}
            columns={columns}
            localization={
              i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
            }
            displayColumnDefOptions={{
              "mrt-row-actions": {
                muiTableHeadCellProps: {
                  align: "left",
                },
                size: 50,
              },
            }}
            enableExpandAll={false}
            renderDetailPanel={({ row }) => {
              return (
                <Properties
                  row={row.original}
                  tags={tags}
                  onSave={updateIntent}
                  intents={data}
                  agentPages={agentPages}
                  userPermissions={userPermissions}
                  noTagsPermissions={noTagsPermissions}
                />
              );
            }}
            enableEditing
            enableColumnOrdering
            positionActionsColumn="last"
            editDisplayMode="modal"
            enableGlobalFilter={false}
            renderRowActions={({ row }) => (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip
                  title={
                    !userPermissions ? t("edit") : t("noPermissionTooltip")
                  }
                  placement="left">
                  <span>
                    <IconButton
                      id="edit-icon"
                      disabled={userPermissions}
                      onClick={() => {
                        setEditIntent(row.original);
                        setEditModalOpen(true);
                      }}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    !userPermissions ? t("delete") : t("noPermissionTooltip")
                  }
                  placement="right">
                  <span>
                    <IconButton
                      id="delete-icon"
                      sx={{ color: "deleteIcon.color" }}
                      disabled={
                        deleteValidate(row.original.name) || userPermissions
                      }
                      onClick={() => {
                        setDeleteModalOpen(true);
                        setEditIntent(row.original);
                      }}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Tooltip
                  title={!userPermissions ? "" : t("noPermissionTooltip")}>
                  <span>
                    <Button
                      id="new-intent-button"
                      variant="contained"
                      disabled={userPermissions}
                      onClick={() => setNewModalOpen(true)}>
                      {t("intents:newIntent")}
                    </Button>
                  </span>
                </Tooltip>
                <InputLabel
                  id="agent-label"
                  sx={{
                    display: "inline",
                    height: "fit-content",
                    marginRight: "10px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "2vw",
                  }}>
                  {t("intents:agent")}
                </InputLabel>
                <FormControl variant="standard">
                  <Select
                    id="combo-agent"
                    value={accountSelected}
                    onChange={(e) => setAccountSelected(e.target.value)}
                    sx={{
                      height: "fit-content",
                      marginTop: "auto",
                      marginBottom: "auto",
                      width: "10vw",
                      textAlign: "left",
                    }}>
                    {accountNames.map((x, index) => (
                      <MenuItem
                        key={index}
                        value={x.name}
                        id={`menu-item-${index}`}>
                        {x.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div>
                  <Button
                    color="info"
                    //className="button-intent-download"
                    onClick={() => prepareDataForExport(data)}
                    disabled={data.length === 0}
                    sx={{
                      marginLeft: "20%",
                    }}
                    variant="contained">
                    {t("intents:download")}
                  </Button>
                </div>
              </div>
            )}
          />
        </ThemeProvider>
      </LocalizationProvider>
      <EditModal
        deleteValidate={deleteValidate}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSave={(edit) => {
          updateIntent(edit);
        }}
        intent={editIntent}
        validateNoDuplicateName={validateNoDuplicateName}
        validateNoDuplicatePhrases={validateNoDuplicatePhrases}
      />
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteIntent(editIntent);
        }}
      />
      <MemoizedNewIntent
        agentPages={agentPages}
        open={newModalOpen}
        onClose={() => setNewModalOpen(false)}
        validateNoDuplicateName={validateNoDuplicateName}
        validateNoDuplicatePhrases={validateNoDuplicatePhrases}
        agentSelected={accountSelected}
        allTags={tags}
        saveIntent={saveIntent}
        intents={data}
        userPermissions={userPermissions}
      />
      <TracesHistory
        traces={traces}
        agentPages={agentPages}
        traces_intents_toggle={traces_intents_toggle}
        handlePublish={handlePublish}
        handleDelete={handleDeleteTraces}
      />
    </React.Fragment>
  );
};

export default Intents;
