import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../shared/helper/validations";

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

const EditModal = ({ rowdata, users, clientList, open, onClose, onSave }) => {
  const { t } = useTranslation("users");
  const [username, setUsername] = useState("");
  const [_id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [lock, setLock] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [clients, setClients] = useState([]);

  const [types] = useState([
    {
      name: "Root",
      value: "Root",
    },
    {
      name: "User",
      value: "User",
    },
    {
      name: "Bot",
      value: "Bot",
    },
    {
      name: "API",
      value: "API",
    },
  ]);
  const [type, setType] = useState("");

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setUsername(rowdata.username);
    setId(rowdata._id);
    setEmail(rowdata.email);
    setType(rowdata.type);
    let filteredClients = Array.isArray(rowdata.clients)
      ? rowdata.clients.filter((client) =>
        clientList.some((clientObj) => clientObj.name === client),
      )
      : [];
    setClients(filteredClients);
    setLock(rowdata.lock);
    setTwoFactorAuth(rowdata.twoFactorAuth);
  }, [rowdata, clientList]);

  const validateEmpty = (field) => {
    return field === undefined || field === "" ? false : true;
  };

  const validateEmailLocal = useCallback(
    (email) => {
      if (!validateEmpty(email)) {
        return t("validationEmailEmpty");
      }

      if (!validateEmail(email)) {
        return t("validationEmail");
      }

      if (users) {
        for (let i = 0; i < users.length; i++) {
          if (users[i].email === email && users[i]._id !== _id) {
            return t("validationEmailAlreadyExist");
          }
        }
        return false;
      }
      return false;
    },
    [_id, t, users],
  );

  const validate = useCallback(() => {
    const temp = {};
    temp.email = validateEmailLocal(email);
    temp.clients =
      clients?.length !== 0 || type === "Root"
        ? false
        : t("validationClientsEmpty");

    setErrors(temp);
  }, [validateEmailLocal, email, t, clients, type]);

  useEffect(() => {
    validate();
  }, [email, type, clients, validate]);

  const getOptionClientLabel = useMemo(() => (option) => option.name, []);
  const getOptionTypeLabel = useMemo(() => (option) => option.name, []);

  const valueClients = useMemo(
    () => clientList.filter((x) => clients?.includes(x.name)),
    [clientList, clients],
  );

  const valueType = useMemo(
    () => types.find((x) => x.name === type),
    [types, type],
  );

  const handleClientsChange = useCallback(
    (event, newValue) => {
      if (newValue !== null) {
        let clientsNames = [];
        newValue.forEach((value) => {
          clientsNames.push(value.name);
        });
        setClients(clientsNames);
      } else {
        setClients([]);
      }
    },
    [setClients],
  );

  const renderInputTypes = useMemo(
    () =>
      function renderInputTypesTextfield(params) {
        return (
          <TextField
            variant="standard"
            {...params}
            label={t("type") + "*"}
            error={errors.type}
            helperText={errors.type ? errors.type : ""}
          />
        );
      },
    [(t, errors.type)],
  );

  const renderInputClients = useMemo(
    () =>
      function renderInputClientsTextfield(params) {
        return (
          <TextField
            variant="standard"
            {...params}
            label={t("clients") + "*"}
            error={errors.clients}
            helperText={errors.clients}
          />
        );
      },
    [(t, errors.clients)],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Typography variant="h5" gutterBottom sx={{ padding: "1rem" }}>
        {t("modalTitleEdit")}
      </Typography>
      <Box         
        sx={{
          maxWidth:"90%",
          display: "flex",
          flexDirection: "column",
          marginLeft:"10%",
          marginRight:"10%",
          alignItems:"center",
          padding: "1rem",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            gap: "1rem",
          }}>
          <TextField
            id="modal-username"
            variant="standard"
            label={t("username") + "*"}
            disabled={true}
            sx={{ width: "100%", marginBottom: "2rem", marginTop: "2rem" }}
            value={username}
          />
          <TextField
            id="modal-email"
            variant="standard"
            label={t("email") + "*"}
            sx={{
              width: "100%",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
            value={email}
            error={errors.email}
            helperText={errors.email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Autocomplete
            id="modal-type"
            variant="standard"
            options={types}
            disabled={true}
            getOptionLabel={getOptionTypeLabel}
            value={valueType}
            sx={{ width: "100%", marginTop: "1rem", marginBottom: "1rem" }}
            renderInput={renderInputTypes}
          />
          <Autocomplete
            id="modal-clients"
            multiple
            variant="standard"
            options={clientList}
            value={valueClients}
            getOptionLabel={getOptionClientLabel}
            sx={{
              width: "100%",
              marginTop: "1rem",
              marginBottom: "1rem",
              display: type === "Root" ? "none" : "",
            }}
            onChange={handleClientsChange}
            renderInput={renderInputClients}
          />
        </Box>
        <Box 
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start",
          }}>    
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >      
            <Switch
              id="edit-modal-switch"
              name="value"
              label="Lock"
              checked={lock}
              onChange={(e) => setLock(e.target.checked)}
            />
            <Typography variant="h6" sx={{ padding: "10px" }}>
          Bloqueado
            </Typography>
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>  
            <Switch
              id="edit-modal-auth2fa-switch"
              name="value"
              label={t("2fa")}
              checked={twoFactorAuth}
              onChange={(e) => setTwoFactorAuth(e.target.checked)}
            />
            <Typography variant="h6" sx={{ padding: "10px" }}>{
              t("twoFactorAuth")}
            </Typography>
          </Box>
        </Box>
      </Box>    
      <DialogActions>
        <Button id="modal-cancel-button" onClick={onClose}>
          {t("modalCancel")}
        </Button>
        <Button
          id="modal-save-button"
          variant="contained"
          color="primary"
          disabled={Object.keys(errors).some((x) => errors[x])}
          onClick={() =>
            onSave({
              username,
              email,
              clients,
              type,
              lock,
              twoFactorAuth,
            })
          }>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
