import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { get, post, put, remove } from "../shared/http/httpService";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import {
  ThemeProvider,
  Tooltip,
  IconButton,
  Box,
  Button,
  Chip,
  Typography,
  Dialog,
} from "@mui/material";
import { orderAlphabeticallyWithAttribute } from "../shared/helper/orderAlphabetically";
import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import { Delete, Edit } from "@mui/icons-material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { DeleteRowModal } from "../shared/helper/tableHelper";
import EditAgentModal from "./Components/EditAgentModal.component";
import AgentVars from "./Components/AgentsVars.component";
import { MaterialReactTable } from "material-react-table";
import { useSelector } from "react-redux";
import Transition from "../shared/helper/transitionDialog";

const Agents = () => {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation("agents");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const theme = useSelector((state) => state.theme);
  const [editRow, setEditRow] = useState(null);
  const [config, setConfig] = useState({});
  const [modalRestartOpen, setModalRestartOpen] = useState(false);

  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "agents" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };
  const columns = useMemo(() => [
    {
      header: t("name"),
      accessorFn: (original) => original.name, //alternate way
      id: "name",
      size: 200,
      Cell: ({ cell }) => {
        return (
          <Typography
            variant="h10"
            style={{
              fontWeight: cell.row.original.is_default ? "bold" : "normal",
            }}>
            {cell.row.original.name}
          </Typography>
        );
      },
    },
    {
      header: t("displayName"),
      accessorFn: (original) => original.display_name,
      id: "display_name",
      size: 150,
    },
    {
      header: t("knowledge"),
      accessorFn: (original) => original.knowledge,
      id: "knowledge",
      size: 200,
      Cell: ({ cell }) => {
        let arrayKnowledge = cell.getValue();
        return (
          <div>
            {Array.isArray(arrayKnowledge) &&
              arrayKnowledge.map((knowledge, index) => (
                <Chip key={index} label={knowledge} variant="outlined" />
              ))}
          </div>
        );
      },
    },
  ]);

  useEffect(() => {
    getAgents();
    getUserPermisions();
  }, []);

  useEffect(() => {
    getConfig();
  }, [data]);

  const getAgents = () => {
    get("/agents")
      .then(async (data) => {
        let newData = orderAlphabeticallyWithAttribute(data.agents, "name");
        let defaultAgent = newData.find((agent) => agent.is_default === true);
        newData.splice(newData.indexOf(defaultAgent), 1);
        newData.unshift(defaultAgent);
        setData(setParentId(newData));
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorGettingAgents"), {
          variant: "error",
        });
      });
    setEditRow(null);
  };

  const newAgent = (row) => {
    const body = {
      name: row.name,
      display_name: row.displayName,
      knowledge: row.knowledge,
      self_service: config.self_service,
    };
    post("/agents", body)
      .then(() => {
        enqueueSnackbar(t("agents:newAgent"), {
          variant: "success",
        });
        getAgents();
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorNewAgent"), {
          variant: "error",
        });
      });
    setEditModalOpen(false);
  };

  const updateAgent = (row) => {
    const body = {
      _id: editRow.original._id,
      agent_name: row.name,
      display_name: row.displayName,
      knowledge: row.knowledge,
      knowledge_update:
        JSON.stringify(editRow.original.knowledge) !==
        JSON.stringify(row.knowledge)
          ? true
          : false,
    };
    put("/agents", body)
      .then(async () => {
        enqueueSnackbar(t("agents:agentUpdated"), {
          variant: "success",
        });
        getAgents();
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorAgentUpdated"), {
          variant: "error",
        });
      });
    setEditModalOpen(false);
    setEditRow(null);
  };

  const deleteAgent = async (row) => {
    try {
      const response = await remove("/agents", {
        _id: row.original._id,
        agent: row.original.name,
      });

      if (response) {
        enqueueSnackbar(t("agents:agentDeleted"), {
          variant: "success",
        });
        getAgents();
      }
      setEditRow(null);
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.validation
      ) {
        error.response.data.validation.forEach((validationItem) => {
          let problematic;
          switch (validationItem.validate) {
            case "foundAgentTransfer":
              problematic = validationItem.problematicAgents;
              enqueueSnackbar(
                `${t("agents:errorfoundAgentTransfer")} ${problematic.join(
                  ", ",
                )}`,
                { variant: "error" },
              );
              break;
            case "foundAgentKnowledge":
              problematic = validationItem.problematicAgents;
              enqueueSnackbar(
                `${t("agents:errorfoundAgentKnowledge")} ${problematic.join(
                  ", ",
                )}`,
                { variant: "error" },
              );
              break;
            case "isAgentDefault":
              enqueueSnackbar(t("agents:errorisAgentDefault"), {
                variant: "error",
              });
              break;
            default:
              enqueueSnackbar(t("agents:errorAgentDeleted"), {
                variant: "error",
              });
          }
        });
      } else {
        enqueueSnackbar(t("agents:errorAgentDeleted"), { variant: "error" });
      }
      setEditRow(null);
    }
  };

  const setParentId = (data) => {
    for (const agent of data) {
      const parentId = agent._id;
      const parentName = agent.name;
      for (let conf_var of agent.vars) {
        conf_var.parentId = parentId;
        conf_var.parentName = parentName;
      }
    }
    return data;
  };

  const getConfig = () => {
    get("/clients/config")
      .then(async (data) => {
        let config = {};
        data.config.forEach((c) => {
          config[c.name] = c.value;
        });

        setConfig(config);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingConfig"), {
          variant: "error",
        });
      });
  };

  const restartConfig = () => {
    post("/agents/reset-vars", { agentName: editRow.original.name })
      .then(() => {
        enqueueSnackbar(t("agents:agentRestarted"), {
          variant: "success",
        });
        getAgents();
      })
      .catch(() => {
        enqueueSnackbar(t("agents:errorAgentRestarted"), {
          variant: "error",
        });
      });
  };

  const validateLimit = useMemo(() => {
    if (config.self_service && config.self_service === "true") {
      if (data.length >= config.self_service_agents_limit) {
        return true;
      }
    }
    return false;
  }, [config, data]);

  return (
    <React.Fragment>
      <div id="agents-table">
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <Typography variant="h4" sx={{ mb: "1rem" }}>
            {t("agents")}
          </Typography>
          <MaterialReactTable
            localization={
              i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
            }
            positionActionsColumn="last"
            columns={columns}
            data={data}
            enableColumnOrdering
            editDisplayMode="modal"
            enableEditing
            onEditingRowSave={updateAgent}
            enableDelete
            enableFilterMatchHighlighting
            displayColumnDefOptions={{
              "mrt-row-actions": {
                muiTableHeadCellProps: {
                  align: "left",
                },
                size: 50,
              },
            }}
            renderRowActions={({ row }) => (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    userPermissions ? t("editButton") : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="edit-icon"
                      onClick={() => {
                        setEditModalOpen(true);
                        setEditRow(row);
                      }}
                      disabled={!userPermissions}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    userPermissions
                      ? t("deleteButton")
                      : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="delete-icon"
                      sx={{ color: "deleteIcon.color" }}
                      disabled={row.original.is_default || !userPermissions}
                      onClick={() => {
                        setDeleteModalOpen(true);
                        setEditRow(row);
                      }}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    userPermissions
                      ? t("restartButton")
                      : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="restart-icon"
                      disabled={!userPermissions}
                      onClick={() => {
                        setModalRestartOpen(true);
                        setEditRow(row);
                      }}>
                      <RestartAltIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Tooltip
                arrow
                placement="right"
                title={
                  !userPermissions
                    ? t("noPermissionTooltip")
                    : validateLimit
                      ? t("limitReached", {
                        limit: config.self_service_agents_limit,
                      })
                      : ""
                }>
                <span>
                  <Button
                    className="mrt-create-new-account-button"
                    id="mrt-create-new-account-button"
                    onClick={() => {
                      if (!validateLimit) {
                        setEditModalOpen(true);
                      }
                    }}
                    variant="contained"
                    disabled={!userPermissions || validateLimit}>
                    {t("addButton")}
                  </Button>
                </span>
              </Tooltip>
            )}
            enableExpandAll={false}
            renderDetailPanel={({ row }) => {
              return (
                <Fragment>
                  {
                    <AgentVars
                      getAgents={getAgents}
                      vars={row.original.vars}
                      userPermissions={userPermissions}
                    />
                  }
                </Fragment>
              );
            }}></MaterialReactTable>
        </ThemeProvider>
      </div>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setEditRow(null);
        }}
        onDelete={() => {
          deleteAgent(editRow);
          setDeleteModalOpen(false);
        }}
        title={t("titleDeleteModal")}
      />
      <EditAgentModal
        open={editModalOpen}
        rowData={{
          id: editRow?.original?._id,
          displayName: editRow?.original?.display_name,
          name: editRow?.original?.name,
          knowledge: editRow?.original?.knowledge,
          agents: data,
        }}
        onClose={() => {
          setEditModalOpen(false);
          setEditRow(null);
        }}
        onSave={(agent, type) => {
          if (type === "new") {
            newAgent(agent);
          } else {
            updateAgent(agent);
          }
        }}></EditAgentModal>
      <Dialog
        TransitionComponent={Transition}
        open={modalRestartOpen}
        onClose={() => setModalRestartOpen(false)}>
        <Box sx={{ p: 4 }}>
          <Typography variant="h5">{t("restartAgent")}</Typography>
          <Typography>
            {t("restartAgentText", { agentName: editRow?.original?.name })}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              id="cancel-restart-var-button"
              onClick={() => {
                setModalRestartOpen(false);
                setEditRow(null);
              }}
              sx={{ mr: 2 }}>
              {t("cancel")}
            </Button>
            <Button
              id="confirm-restart-var-button"
              onClick={() => {
                restartConfig();
                setModalRestartOpen(false);
              }}
              variant="contained">
              {t("restart")}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default Agents;
