import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Transition from "../shared/helper/transitionDialog";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";

const EditModal = ({ typeModal, data, clientList, open, onClose, onSave }) => {
  const [inboundName, setInboundName] = useState("");
  const [transferName, setTransferName] = useState("");
  const [errors, setErrors] = useState({});
  const [dirty, setDirty] = useState({});
  const { t } = useTranslation("chattigo");

  useEffect(() => {
    if (typeModal !== "new") {
      setInboundName(data.inboundName);
      setTransferName(data.transferName);
      setDirty({
        inboundName: true,
        transferName: true,
      });
    } else {
      setInboundName("");
      setTransferName("");
      setDirty({});
    }
  }, [data, typeModal]);

  const validate = useCallback(() => {
    const temp = {};
    temp.inboundName = inboundName ? "" : t("modalErrorInboundRequired");
    temp.transferName = transferName ? "" : t("modalErrorTransferRequired");
    if (!dirty.inboundName && inboundName !== "") {
      setDirty({ ...dirty, inboundName: true });
    }
    if (!dirty.transferName && transferName !== "") {
      setDirty({ ...dirty, transferName: true });
    }

    if (transferName) {
      if (clientList.find((x) => x.name === transferName) === undefined) {
        temp.transferName = t("modalErrorTransferNotExist");
      }
    }
    setErrors({ ...temp });
  }, [clientList, dirty, inboundName, t, transferName]);

  useEffect(() => {
    validate();
  }, [inboundName, transferName, validate]);

  const sortedClientList = useMemo(
    () => [...clientList].sort((a, b) => a.name.localeCompare(b.name)),
    [clientList],
  );

  const getOptionLabel = useCallback((option) => option.display_name, []);

  const value = useMemo(() => {
    const foundClient = clientList.find((x) => x.name === transferName);
    return foundClient || null;
  }, [clientList, transferName]);

  const handleChange = useCallback(
    (event, newValue) => {
      if (newValue !== null) {
        setTransferName(newValue.name);
      } else {
        setTransferName("");
      }
    },
    [setTransferName],
  );

  const renderInput = useCallback(
    (params) => (
      <TextField
        variant="standard"
        {...params}
        label={t("transferName") + "*"}
        error={errors.transferName && dirty.transferName}
        helperText={
          errors.transferName && dirty.transferName ? errors.transferName : ""
        }
      />
    ),
    [t, errors.transferName, dirty.transferName],
  );

  const hasErrors = useMemo(
    () => Object.keys(errors).some((x) => errors[x] !== ""),
    [errors],
  );

  const handleSave = useCallback(() => {
    onSave({ inboundName, transferName });
  }, [inboundName, transferName, onSave]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      TransitionComponent={Transition}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "0.5rem",
        },
      }}>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {typeModal === "new" ? t("modalTitleNew") : t("modalTitleEdit")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          id="modal-inbound-client-name"
          variant="standard"
          label={t("inboundName") + "*"}
          autoFocus
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={inboundName}
          error={errors.inboundName && dirty.inboundName}
          helperText={
            errors.inboundName && dirty.inboundName ? errors.inboundName : ""
          }
          onChange={(e) => {
            setInboundName(e.target.value);
          }}
        />
        {(value !== undefined || typeModal === "new") && (
          <Autocomplete
            id="modal-transfer-client-name"
            variant="standard"
            options={sortedClientList}
            getOptionLabel={getOptionLabel}
            value={value}
            sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
            onChange={handleChange}
            renderInput={renderInput}
          />
        )}
      </Box>
      <DialogActions>
        <Button id="modal-chattigo-cancel" onClick={onClose}>{t("modalCancel")}</Button>
        <Button
          id="modal-chattigo-save"
          variant="contained"
          color="primary"
          disabled={hasErrors}
          onClick={handleSave}>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
