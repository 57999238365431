import { createTheme } from "@mui/material/styles";

const headingStyles = {
  fontFamily: ["'Helvetica Neue'", "Helvetica", "sans-serif"].toString(),
  fontWeight: 700,
};
export const lightTheme = createTheme({
  palette: {
    mode: "light",
    type: "light",
    primary: {
      main: "#2C2F88",
    },
    secondary: {
      main: "rgba(0,0,0,0)",
    },
    default: {
      main: "#ffffff",
    },
    background: {
      default: "#eaeaea",
    },
    chat: {
      main: "#fafafa",
    },
    textColor: "#000000",
    typography: {
      fontFamily: ["'Fira Mono'", "Menlo", "monospace"].toString(),
      h1: headingStyles,
      h2: headingStyles,
      h3: headingStyles,
      h4: headingStyles,
      h5: headingStyles,
      h6: {
        fontWeight: 400,
      },
      overline: {
        fontSize: "0.875rem",
        fontWeight: 500,
        letterSpacing: 2,
      },
      button: {
        fontWeight: 500,
      },
    },
    "sc-chat-window-opened": {
      boxShadow: 0,
      background: "#424242",
    },
    "sc-message-list": {
      background: "#353535",
    },
    "sc-user-input": {
      background: "#424242",
    },
    chatWindow: {
      background: "",
      shadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
      color: "#000",
    },
    chatTextBox: {
      background: "",
    },
    chatInput: {
      background: "",
    },
    chatMessage: {
      color: "",
    },
    boxAnalytics: {
      background: "#ffffff",
    },
    cardQuickReply: {
      background: "#ffffff",
    },
    deleteIcon: {
      color: "#D62828",
    },
    disabledButton: {
      backgroundColor: "#BABCCA",
    },
    permissionsGrid: {
      backgroundColor: "#ffffff",
    },
    tracesName: {
      delete: "#F85149",
      new: "#3FB950",
    },
    tracesType: {
      create: "#3FB950",
      update: "#337AFF",
      delete: "#F85149",
    },
    disabledFab: {
      color: "#CCC",
    },
    flowgraphBg: "#eee",
    node: "fff",
    detailsPanel: "#ffffff",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        a {
          color: #000;
          text-decoration: none;
          vertical-align: middle;
          margin-right: 10px;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-contained": {
            textTransform: "none",
            backgroundColor: "#2c2f88",
            borderRadius: "50px",
            fontFamily: "Helvética Neue LT Std - 75 Bold",
            fontWeight: 700,
            fontSize: 18,
            lineHeight: "22px",
            wordWrap: "break-word",
            color: "#F1F1F1",
            "&:hover": {
              background: "var(--primary-azul-brillante, #3A3EB5)",
              boxShadow:
                "0px 2px 7px 0px rgba(44, 47, 136, 0.60), 0px 0.77px 2.23px 0px rgba(44, 47, 136, 0.40), 0px 0.163px 0.57px 0px #2C2F88",
            },
            "&.Mui-disabled": {
              backgroundColor: "#D5D5E7",
              color: "#7E7E7E",
            },
          },
          "&.MuiButton-text": {
            fontFamily: "Helvética Neue LT Std - 24px",
            fontSize: "14px",
            color: "#2c2f88",
          },
          "&.MuiButton-containedError": {
            backgroundColor: "#ef5350",
            color: "#1a1a1a",
            "&:hover": {
              backgroundColor: "#b71c1c",
            },
          },
          "&.MuiButton-containedSuccess": {
            backgroundColor: "#84bf40",
            color: "#1a1a1a",
            "&:hover": {
              backgroundColor: "#5e8e2f",
            },
            "&.Mui-disabled": {
              backgroundColor: "#D5D5E7",
              boxShadow: "",
              color: "#7E7E7E",
            },
          },
          "&.MuiButton-outlined": {
            textTransform: "none",
            borderRadius: "50px",
            fontFamily: "Helvetica Neue LT Std - 75 Bold",
            fontWeight: 700,
            fontSize: 18,
            lineHeight: "22px",
            wordWrap: "break-word",
            color: "#2c2f88",
            border: "1px solid #2c2f88",
            "&:hover": {
              background: "rgba(44, 47, 136, 0.08)",
              borderColor: "#3A3EB5",
            },
            "&.Mui-disabled": {
              borderColor: "#D5D5E7",
              color: "#7E7E7E",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.MuiIconButton-root": {
            backgroundColor: "#2c2f88",
            borderRadius: "50%", // make it circular
            color: "#fff",
            "&:hover": {
              background: "var(--primary-azul-brillante, #3A3EB5)",
              boxShadow:
                "0px 2px 7px 0px rgba(44, 47, 136, 0.60), 0px 0.77px 2.23px 0px rgba(44, 47, 136, 0.40), 0px 0.163px 0.57px 0px #2C2F88",
            },
            "&.Mui-disabled": {
              backgroundColor: "rgba(44, 47, 136, 0.2)",
              color: "#7e7e7e",
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          style: {
            fontFamily: "Helvetica Neue LT Std - 75 Bold",
            color: "#1a1a1a",
            backgroundColor: "rgba(213, 213, 231, 0.2)",
          },
        },
      },
      styleOverrides: {
        root: {
          "& .MuiFilledInput-root": {
            color: "#1a1a1a",
            "&:before": {
              borderBottomColor: "#7E7E7E", // Color de la línea antes del foco
              borderBottomWidth: "1px", // Grosor de la línea antes del foco
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#2C2F88", // Color de la línea al pasar el cursor
              borderBottomWidth: "2px", // Grosor de la línea al pasar el cursor
            },
            "&:after": {
              borderBottomColor: "#2C2F88", // Color de la línea después del foco
              borderBottomWidth: "2px", // Grosor de la línea después del foco
            },
            "&.Mui-focused:before": {
              borderBottomColor: "#2C2F88", // Color de la línea en foco
              borderBottomWidth: "2px", // Grosor de la línea en foco
            },
            transition:
              "border-bottom-color 0.3s ease, border-bottom-width 0.3s ease", // Transición suave
          },
          "& .MuiFilledInput-root.Mui-error": {
            "&:before": {
              borderBottomColor: "#9F1919", // Color de la línea antes del foco en estado de error
              borderBottomWidth: "2px", // Grosor de la línea antes del foco en estado de error
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#9F1919", // Color de la línea al pasar el cursor en estado de error
              borderBottomWidth: "2px", // Grosor de la línea al pasar el cursor en estado de error
            },
            "&:after": {
              borderBottomColor: "#9F1919", // Color de la línea después del foco en estado de error
              borderBottomWidth: "2px", // Grosor de la línea después del foco en estado de error
            },
            "&.Mui-focused:before": {
              borderBottomColor: "#9F1919", // Color de la línea en foco en estado de error
              borderBottomWidth: "2px", // Grosor de la línea en foco en estado de error
            },
          },
          "& .MuiInputLabel-root": {
            color: "#7E7E7E", // Color del label
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#2C2F88", // Color del label en foco
          },
          "& .MuiInputLabel-root.Mui-error": {
            color: "#9F1919", // Color del label en estado de error
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#D5D5E7",
          "&.Mui-checked": {
            color: "#2C2F88",
          },
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#2C2F88",
          },
        },
        track: {
          backgroundColor: "#7E7E7E",
          opacity: 0.38,
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    type: "dark",
    primary: {
      main: "##2C2F88",
    },
    secondary: {
      main: "rgba(0,0,0,0)",
    },
    default: {
      main: "#2C2F88",
    },
    chat: {
      main: "#fafafa",
    },
    background: {
      default: "#303030",
    },
    textColor: "#ffffff",
    typography: {
      fontFamily: ["'Fira Mono'", "Menlo", "monospace"].toString(),
      h1: headingStyles,
      h2: headingStyles,
      h3: headingStyles,
      h4: headingStyles,
      h5: headingStyles,
      h6: {
        fontWeight: 400,
      },
      overline: {
        fontSize: "0.875rem",
        fontWeight: 500,
        letterSpacing: 2,
      },
      button: {
        fontWeight: 500,
      },
    },
    "sc-chat-window-opened": {
      background: "#424242",
    },
    chatWindow: {
      background: "#353535",
      shadow: 0,
      color: "#fff",
    },
    chatTextBox: {
      background: "#242424",
    },
    chatInput: {
      background: "#424242",
    },
    chatMessage: {
      color: "#fff",
    },
    boxAnalytics: {
      background: "#1e1e1e",
    },
    cardQuickReply: {
      background: "#292929",
    },
    deleteIcon: {
      color: "#D62828",
    },
    table: {
      background: "#121212",
    },
    disabledButton: {
      backgroundColor: "#80849D",
    },
    permissionsGrid: {
      backgroundColor: "#1F1F1F",
    },
    tracesName: {
      delete: "#F85149",
      new: "#3FB950",
    },
    tracesType: {
      create: "#3FB950",
      update: "#337AFF",
      delete: "#F85149",
    },
    disabledFab: {
      color: "#CCC",
    },
    flowgraphBg: "#0d0e16",
    node: "fff",
    detailsPanel: "#4f4f4f",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        a {
          color: #fff;
          text-decoration: none;
          vertical-align: middle;
          margin-right: 10px;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-contained": {
            textTransform: "none",
            backgroundColor: "#2c2f88",
            borderRadius: "50px",
            fontFamily: "Helvética Neue LT Std - 75 Bold",
            fontWeight: 700,
            fontSize: 18,
            lineHeight: "22px",
            wordWrap: "break-word",
            color: "#F1F1F1",
            "&:hover": {
              background: "var(--primary-azul-brillante, #3A3EB5)",
              boxShadow:
                "0px 2px 7px 0px rgba(44, 47, 136, 0.60), 0px 0.77px 2.23px 0px rgba(44, 47, 136, 0.40), 0px 0.163px 0.57px 0px #2C2F88",
            },
            "&.Mui-disabled": {
              backgroundColor: "#D5D5E7",
              color: "#7E7E7E",
            },
          },
          "&.MuiButton-text": {
            fontFamily: "Helvética Neue LT Std - 24px",
            fontSize: "14px",
            color: "#2c2f88",
          },
          "&.MuiButton-containedError": {
            backgroundColor: "#b23939",
            color: "#1a1a1a",
            "&:hover": {
              backgroundColor: "#e53935",
            },
          },
          "&.MuiButton-containedSuccess": {
            backgroundColor: "#5e8e2f",
            color: "#1a1a1a",
            "&:hover": {
              backgroundColor: "#84bf40",
            },
            "&.Mui-disabled": {
              backgroundColor: "#D5D5E7",
              boxShadow: "",
              color: "#7E7E7E",
            },
          },
          "&.MuiButton-outlined": {
            textTransform: "none",
            borderRadius: "50px",
            fontFamily: "Helvetica Neue LT Std - 75 Bold",
            fontWeight: 700,
            fontSize: 18,
            lineHeight: "22px",
            wordWrap: "break-word",
            color: "#2c2f88",
            border: "1px solid #2c2f88",
            background: "#D5D5E7",
            "&:hover": {
              background: "#E9E9FB",
              borderColor: "#3A3EB5",
            },
            "&.Mui-disabled": {
              borderColor: "#D5D5E7",
              color: "#7E7E7E",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.MuiIconButton-root": {
            backgroundColor: "#2c2f88",
            borderRadius: "50%", // make it circular
            color: "#fff",
            "&:hover": {
              background: "var(--primary-azul-brillante, #3A3EB5)",
              boxShadow:
                "0px 2px 7px 0px rgba(44, 47, 136, 0.60), 0px 0.77px 2.23px 0px rgba(44, 47, 136, 0.40), 0px 0.163px 0.57px 0px #2C2F88",
            },
            "&.Mui-disabled": {
              backgroundColor: "rgba(44, 47, 136, 0.2)",
              color: "#7e7e7e",
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          style: {
            fontFamily: "Helvetica Neue LT Std - 75 Bold",
            color: "#1a1a1a",
            backgroundColor: "rgba(213, 213, 231, 0.2)",
          },
        },
      },
      styleOverrides: {
        root: {
          "& .MuiFilledInput-root": {
            color: "#1a1a1a", // Color del texto
            "&:before": {
              borderBottomColor: "#7E7E7E", // Color de la línea antes del foco
              borderBottomWidth: "1px", // Grosor de la línea antes del foco
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#2C2F88", // Color de la línea al pasar el cursor
              borderBottomWidth: "2px", // Grosor de la línea al pasar el cursor
            },
            "&:after": {
              borderBottomColor: "#2C2F88", // Color de la línea después del foco
              borderBottomWidth: "2px", // Grosor de la línea después del foco
            },
            "&.Mui-focused:before": {
              borderBottomColor: "#2C2F88", // Color de la línea en foco
              borderBottomWidth: "2px", // Grosor de la línea en foco
            },
            transition:
              "border-bottom-color 0.3s ease, border-bottom-width 0.3s ease", // Transición suave
          },
          "& .MuiFilledInput-root.Mui-error": {
            "&:before": {
              borderBottomColor: "#9F1919", // Color de la línea antes del foco en estado de error
              borderBottomWidth: "2px", // Grosor de la línea antes del foco en estado de error
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#9F1919", // Color de la línea al pasar el cursor en estado de error
              borderBottomWidth: "2px", // Grosor de la línea al pasar el cursor en estado de error
            },
            "&:after": {
              borderBottomColor: "#9F1919", // Color de la línea después del foco en estado de error
              borderBottomWidth: "2px", // Grosor de la línea después del foco en estado de error
            },
            "&.Mui-focused:before": {
              borderBottomColor: "#9F1919", // Color de la línea en foco en estado de error
              borderBottomWidth: "2px", // Grosor de la línea en foco en estado de error
            },
          },
          "& .MuiInputLabel-root": {
            color: "#7E7E7E", // Color del label
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#2C2F88", // Color del label en foco
          },
          "& .MuiInputLabel-root.Mui-error": {
            color: "#9F1919", // Color del label en estado de error
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#D5D5E7",
          "&.Mui-checked": {
            color: "#4B518E",
          },
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#4B518E",
          },
        },
        track: {
          backgroundColor: "#7E7E7E",
          opacity: 0.38,
        },
      },
    },
  },
});
