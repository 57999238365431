import { ThemeProvider } from "@emotion/react";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useSnackbar, withSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ChipInput from "../shared/components/ChipInput";
import { DeleteRowModal } from "../shared/helper/tableHelper";
import { get, post, put, remove } from "../shared/http/httpService";
import { store } from "../shared/redux/configureStore";
import { tableDarkTheme, tableLightTheme } from "../shared/theming/table.theme";
import EditModal from "./EditModal.component";
import InfoIcon from "@mui/icons-material/Info";

const NewConfig = () => {
  const { t, i18n } = useTranslation("config");
  const { enqueueSnackbar } = useSnackbar();
  const [editRow, seteditRow] = useState(null);
  const [data, setData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [maxAgents, setMaxAgents] = useState(0);
  const [maxNodes, setMaxNodes] = useState(0);
  const clientSelected = useMemo(
    () => [store.getState().client],
    [store.getState().client.display_name],
  );

  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "config" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  const [types] = useState([
    {
      text: "Bool",
      value: "bool",
    },
    {
      text: "Number",
      value: "int",
    },
    {
      text: "String",
      value: "string",
    },
    {
      text: "String Array",
      value: "sarray",
    },
  ]);

  const variablesDisabledToDelete = [
    "flows_endpoint_search",
    "flows_timeout_polling_rate",
  ];

  const renderValue = (row, cell) => {
    if (row.original.type === "sarray") {
      return (
        <ChipInput
          disabled
          name="value"
          source="value"
          label={t("value")}
          value={cell.getValue()}
        />
      );
    } else {
      return cell.getValue();
    }
  };

  const theme = useSelector((state) => state.theme);

  const columns = useMemo(
    () => [
      {
        header: t("name"),
        accessorFn: (row) => t(row.name),
        size: 300,
        Cell: ({ cell, row }) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={t(`${row.original.name}_tooltip`)}>
                <InfoIcon />
              </Tooltip>
              <span style={{ marginLeft: "10px" }}>{cell.getValue()}</span>
            </div>
          );
        },
      },
      {
        header: t("type"),
        accessorKey: "type",
        size: 300,
        filterVariant: "multi-select",
        filterSelectOptions: types,
        Cell: ({ cell }) => {
          if (cell.getValue() === "int") {
            return "number";
          } else {
            return cell.getValue();
          }
        },
      },
      {
        header: t("value"),
        accessorKey: "value",
        Cell: ({ cell, row }) => {
          return renderValue(row, cell);
        },
      },
    ],
    [i18n.language, t, types],
  );

  const getValueFromRowByType = (row) => {
    let aux = "";
    if (row.type === "sarray") {
      aux = row.valueArray;
    } else {
      aux = row.value;
    }
    return aux;
  };

  const getConfig = () => {
    get("/clients/config", { clientName: clientSelected[0].name })
      .then(async (data) => {
        setData(data.config);
        setMaxNodes(data.maxNodes);
        setMaxAgents(data.maxAgents);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingConfig"), {
          variant: "error",
        });
      });
  };

  const newConfig = (row) => {
    const body = {
      name: row.name,
      type: row.type,
      value: getValueFromRowByType(row),
      clientName: clientSelected[0].name,
    };
    post("/clients/config", body)
      .then(() => {
        enqueueSnackbar(t("configAdded"), {
          variant: "success",
        });
        getConfig();
      })
      .catch((error) => {
        const errorMessage = error.response ? error.response.data : error;
        if (
          errorMessage &&
          errorMessage.status === 422 &&
          errorMessage.message &&
          Array.isArray(errorMessage.message.validation)
        ) {
          errorMessage.message.validation.forEach((element) => {
            let count;
            switch (element.validate) {
              case "self_service_agents_limit":
                count = element.countAgents.toString();
                enqueueSnackbar(`${t("config:errorAgentsLimit")} ${count}`, {
                  variant: "error",
                });
                break;
              case "self_service_nodes_limit":
                count = element.countNodes.toString();
                enqueueSnackbar(`${t("config:errorNodesLimit")} ${count}`, {
                  variant: "error",
                });
                break;
              default:
                enqueueSnackbar(t("errorCreatingConfig"), {
                  variant: "error",
                });
            }
          });
        } else {
          enqueueSnackbar(t("errorCreatingConfig"), {
            variant: "error",
          });
        }
      });
  };

  const updateConfig = (row, values) => {
    const body = {
      _id: row.original._id,
      name: row.original.name,
      type: values.type,
      value: getValueFromRowByType(values),
      clientName: clientSelected[0].name,
    };

    put("/clients/config", body)
      .then(async () => {
        enqueueSnackbar(t("configUpdated"), {
          variant: "success",
        });
        getConfig();
      })
      .catch((error) => {
        if ((error.response.status = 422) && !error.response.data.status) {
          error.response.data.validation.forEach((element) => {
            let count;
            switch (element.validate) {
              case "self_service_agents_limit":
                count = element.countAgents.toString();
                enqueueSnackbar(`${t("config:errorAgentsLimit")} ${count}`, {
                  variant: "error",
                });
                break;
              case "self_service_nodes_limit":
                count = element.countNodes.toString();
                enqueueSnackbar(`${t("config:errorNodesLimit")} ${count}`, {
                  variant: "error",
                });
                break;
              default:
                enqueueSnackbar(t("errorUpdatingConfig"), {
                  variant: "error",
                });
            }
          });
        } else {
          console.error("Error updating config:", error);
          enqueueSnackbar(t("errorUpdatingConfig"), {
            variant: "error",
          });
        }
      });
  };

  const deleteConfig = (row) => {
    remove("/clients/config", {
      name: row.original.name,
      clientName: clientSelected[0].name,
    })
      .then(async () => {
        enqueueSnackbar(t("configDeleted"), {
          variant: "success",
        });
        getConfig();
        setDeleteModalOpen(false);
        seteditRow(null);
      })
      .catch(() => {
        enqueueSnackbar(t("errorDeletingConfig"), {
          variant: "error",
        });
      });
  };

  const table = useMaterialReactTable({
    columns,
    data,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 50,
      },
    },
    positionActionsColumn: "last",
    enableColumnOrdering: true,
    enableGlobalFilter: false,
    editDisplayMode: "modal",
    enableEditing: true,
    onEditingRowSave: updateConfig,
    enableDelete: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip
          arrow
          placement="left"
          title={userPermissions ? t("editButton") : t("noPermissionTooltip")}>
          <span>
            <IconButton
              id="edit-icon"
              onClick={() => {
                setTypeModal("edit");
                setEditModalOpen(true);
                seteditRow(row);
              }}
              disabled={!userPermissions}>
              <Edit />
            </IconButton>
          </span>
        </Tooltip>
        {!variablesDisabledToDelete.includes(row.original.name) && (
          <Tooltip
            arrow
            placement="right"
            title={
              userPermissions ? t("deleteButton") : t("noPermissionTooltip")
            }>
            <span>
              <IconButton
                id="delete-icon"
                sx={{ color: "deleteIcon.color" }}
                onClick={() => {
                  setDeleteModalOpen(true);
                  seteditRow(row);
                }}
                disabled={!userPermissions}>
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <Tooltip
        arrow
        placement="right"
        title={userPermissions ? "" : t("noPermissionTooltip")}>
        <span>
          <Button
            className="mrt-create-new-account-button"
            id="new-config-button"
            onClick={() => {
              setTypeModal("new");
              setEditModalOpen(true);
            }}
            variant="contained"
            disabled={!userPermissions}>
            {t("addButton")}
          </Button>
        </span>
      </Tooltip>
    ),
  });

  useEffect(() => {
    getConfig();
    getUserPermisions();
  }, [clientSelected]);

  return (
    <React.Fragment>
      <div>
        <Typography variant="h4" sx={{ mb: "1rem" }}>
          {t("config")}
        </Typography>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable table={table} />
        </ThemeProvider>
      </div>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteConfig(editRow);
        }}
        title={t("tittleDeleteModal")}
      />
      <EditModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setTypeModal(null);
          getConfig();
        }}
        configs={data}
        data={{
          name: editRow?.original?.name,
          type: editRow?.original?.type,
          value: editRow?.original?.value,
          client: { maxAgents, maxNodes },
        }}
        onSave={(values) => {
          if (typeModal === "new") {
            newConfig(values);
            setEditModalOpen(false);
          } else {
            updateConfig(editRow, values);
            setEditModalOpen(false);
          }
        }}
        typeModal={typeModal}
        valueTypes={types}
      />
    </React.Fragment>
  );
};
export default withTranslation()(withSnackbar(NewConfig));
