import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//
import Appbar from "./Appbar";
import Home from "../../Home/Home.component";
import Files from "../../Files/Files.component";
import Intents from "../../Intents/Intents.component";
import NoMatch from "../../NoMatch/NoMatch.component";
import Pages from "../../Pages/Pages.component";
import Analytics from "../../Analytics/Analytics.component";
import Endpoints from "../../Endpoints/Endpoints.component";
import Flowgraph from "../../Flowgraph/Flowgraph.component";
import RoutesComponent from "../../Routes/Routes.component";
import Users from "../../Users/Users.component";
import Clients from "../../Clients/Clients.component";
import Agents from "../../Agents/Agents.component";
import Config from "../../Config/Config.component";
import Login from "../../Login/components/Login.component";
import ChattigoTable from "../../ChattigoTable/ChattigoTable.component";
import { setAuth, logout as logoutAction } from "../../shared/redux/authSlice";
import WorkingDays from "../../WorkingDays/WorkingDays.component";
import { setClient, updateClientList } from "../redux/clientSlice";
import Preferences from "../../Preferences/Preferences.Component";
import Personalization from "../../Personalization/Personalization.Component";
import Translations from "../../Translations/Translations.component";
import ChatbotScript from "./chatbotScript.component";
import Powerbi from "../../PowerBi/Powerbi.component";
import FlowgraphV2 from "../../FlowgraphV2/Flowgraph-V2.component";
import Conversations from "../../Conversations/Conversations.component";
import Monitoring from "../../Monitoring/Monitoring.Component";

const Router = () => {
  let [isLoading, setLoading] = React.useState(true);
  let [token, setToken] = React.useState(null);
  let [userPermissions, setUserPermissions] = React.useState([]);
  const dispatch = useDispatch();
  let auth = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  let client = useSelector((state) => state.client);

  useEffect(() => {
    if (auth.token) {
      setToken(auth.token);
      dispatch(setAuth(auth.token));
      getUserPermisions();
    } else {
      if (location.pathname !== "/login") {
        dispatch(logoutAction());
        setToken(null);
        navigate("/login");
      }
    }
    setLoading(false);
  }, [auth]);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    setUserPermissions(user.permissions);
  };

  useEffect(() => {
    if (location.pathname === "/login") {
      dispatch(logoutAction());
      setToken(null);
    }
  }, [navigate]);

  const logout = () => {
    dispatch(logoutAction());
    dispatch(setClient({}));
    navigate("/login");
  };

  const updateClients = () => {
    dispatch(updateClientList(true));
  };

  const routesConfig = [
    { name: "intents", component: <Intents />, path: "intents" },
    { name: "noMatch", component: <NoMatch />, path: "match" },
    { name: "pages", component: <Pages />, path: "pages" },
    { name: "report", component: <Analytics />, path: "analytics" },
    { name: "monitoring", component: <Monitoring />, path: "monitoring" },
    { name: "endpoints", component: <Endpoints />, path: "endpoints" },
    { name: "flows", component: <Flowgraph />, path: "flowgraph" },
    { name: "agents", component: <Agents />, path: "agents" },
    { name: "workingDays", component: <WorkingDays />, path: "working" },
    { name: "translations", component: <Translations />, path: "translations" },
    { name: "file", component: <Files />, path: "files" },
    { name: "file", component: <Personalization />, path: "personalization" },
    { name: "routes", component: <RoutesComponent />, path: "routes" },
    {
      name: "clients",
      component: <Clients updateClients={updateClients} />,
      path: "clients",
    },
    { name: "config", component: <Config />, path: "config" },
    { name: "users", component: <Users />, path: "users" },
    { name: "chattigoTable", component: <ChattigoTable />, path: "chattigo" },
    //{ name: "powerbi", component: <Powerbi />, path: "powerbi" },
    {
      name: "conversaciones",
      component: <Conversations />,
      path: "conversaciones",
    },
  ];

  const filteredRoutes = routesConfig.filter((routeConfig) =>
    userPermissions.some(
      (permission) =>
        permission.name === routeConfig.name && permission.fullAccess !== null,
    ),
  );

  const getStyle = (token, pathname) => {
    if (
      (token && pathname === "/flowgraph") ||
      pathname === "/flowgraph-v2" ||
      pathname === "/login"
    ) {
      return {};
    } else if (pathname === "/") {
      return { marginTop: 90, backgroundColor: "#FCFDFC" };
    } else {
      return { margin: 90, marginTop: "180px" };
    }
  };

  return (
    <div>
      {token && <Appbar enabledOptionsMenu={token} logout={logout} />}
      <div style={getStyle(token, location.pathname)}>
        {!isLoading && (
          <Routes>
            <Route path="/" element={<Home token={token} />} />
            <Route path="login" element={<Login />} />
            <Route path="preferences" element={<Preferences />} />
            <Route index element={<Home token={token} />} />
            <Route path="powerbi" element={<Powerbi />} />
            <Route path="flowgraph-v2" element={<FlowgraphV2 />} />
            {filteredRoutes.map((routeConfig) => (
              <Route
                key={routeConfig}
                path={routeConfig.path}
                element={routeConfig.component}
              />
            ))}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </div>
      {token && <ChatbotScript client={client} />}
    </div>
  );
};

export default Router;
